import React, { useState, useEffect } from 'react';
import { Modal, Nav, Tab } from 'react-bootstrap';
import { RetrieveOrder  } from '../../services/OrderService';
import tabData from "./subtabs/tabDataMain.json"
import { extractDate, extractTime, formatPhoneNumber, formatCNPJ, formatCPF } from '../helpers/helpers';

const statusMap = {
    0: 'Pendente',
    1: 'Confirmado',
    2: 'Enviado',
    3: 'Entregue',
    4: 'Cancelado',
    5: 'Rejeitado'
};

function Order() {
    const [order, setOrder] = useState({});
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        if (!toggle) {
            const checkOrder = async () => {
                try {
                    const params = new URLSearchParams(window.location.search);
                    const pValue = params.get('p');
                    if (pValue) {
                        const response = await RetrieveOrder(pValue);
                        if (response.status === 200) {
                            setOrder(response.data.order);
                        } else {
                            console.error('Erro ao obter os produtos:', response.statusText);
                        }
                    }
                } catch (error) {

                }
            };
            checkOrder();
            setToggle(true)
        }
    }, [toggle]);

    return (
        <>
            <div className="row">
                <div className="col-xl-8 col-xxl-12">
                    {order.number ?
                        <div className="h-auto b-hover">
                            <div className="p-3"
                                style={{
                                    boxShadow: '2px 2px 2px #f1f1f1',
                                }}>
                                <div className="d-flex flex-row" style={{ justifyContent: "space-between" }}>
                                    <div className="text-left">
                                        <h3 style={{ marginBottom: 1 }}>Pedido Nº {order.number}</h3>
                                        <p style={{ marginBottom: 1 }}><i className="bi bi-calendar-date text-primary" style={{ marginRight: 4 }}></i> {extractDate(order.orderDate)} | <i className="bi bi-clock  text-primary" style={{ marginRight: 4 }}></i> {extractTime(order.createDate)}</p>
                                        <h3 style={{ marginBottom: 1 }}>{order.store.name}</h3>
                                        <p style={{ marginBottom: 1 }}><i className="bi bi-person-badge text-primary" style={{ marginRight: 3 }}></i> {formatCNPJ(order.store.taxId)} | <i className="bi bi-telephone-fill  text-primary"></i> {formatPhoneNumber(order.store.phone)}</p>
                                    </div>
                                    <div style={{ display: 'flex', gap: 5, paddingTop: 20, flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 3, padding: 3, backgroundColor: 'rgb(234, 234, 234)', borderRadius: 6 }}>
                                            <label className="mb-0">status</label>
                                            <h4 className="mb-0 text-primary">{statusMap[order.orderStatus]}</h4>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                <h4><i className="fa-solid fa-location-dot text-primary" style={{ marginRight: 7 }}></i> {order.address.street + ' ( ' + order.address.zipCode + ' ) ' + ', Nº ' + ' , ' + order.address.city + ' - ' + order.address.state}</h4>
                                <hr />
                                <div className="order-menu">
                                    <h6>Itens</h6>
                                    {
                                        order.orderItems.map((a, b) => (

                                            <div key={'orderItems-' + b} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2 }}>
                                                <div style={{ display: 'flex' }}>
                                                    <img src={'https://core.pskeromais.com.br/' + a.product.images[0].url} />
                                                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                                        <p style={{ fontSize: 18, marginBottom: 0, marginTop: 0, height: 27 }}>{a.product.name}</p>
                                                        <b className="text-primary">{a.quantity + ' x'}</b>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: 5 }}>
                                                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <label style={{ marginRight: 5, fontSize: 9 }}>unit</label>
                                                        <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontSize: 10, borderBottom: '1px dotted rgb(182 182 182', paddingBottom: 4 }}>
                                                            {
                                                                a.product.salePrice ?
                                                                    <i style={{ margin: '0 4px', textDecoration: 'line-through', color: '#a0a0a0' }}>
                                                                        {a.product.salePrice.toFixed(2) + ' R$'}
                                                                    </i> : null
                                                            }
                                                            <b className="text-primary" >
                                                                {' ' + a.unitPrice.toFixed(2) + ' R$'}
                                                            </b>
                                                        </span>
                                                    </span>
                                                    <span style={{ marginTop: 5}}>
                                                        <label style={{ marginRight: 5, fontSize: 9 }}>total</label>
                                                        <b className="text-primary">
                                                            {' ' + a.total.toFixed(2) + ' R$'}
                                                        </b>
                                                    </span>
                                                </div>
                                            </div>

                                        )
                                        )
                                    }
                                    <hr />
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 20, justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                            <h4 className="mb-0">Total do pedido</h4>
                                            <span style={{ display: 'flex', gap: 5, marginBottom: 7, marginTop: 4 }}>
                                                <span style={{ display: 'flex', flexDirection: 'column', padding: 5, borderRadius: 6, backgroundColor: 'rgb(234 234 234)' }}>
                                                    <label style={{ fontSize: 12, margin: 0 }}>entrega</label>
                                                    <span className="text-primary" style={{ fontSize: 15, margin: 0 }}>R$ {' ' + order.deliveryRate.toFixed(2)}</span>
                                                </span>
                                                <span style={{ display: 'flex', flexDirection: 'column', padding: 5, borderRadius: 6, backgroundColor: 'rgb(234 234 234)' }}>
                                                    <label style={{ fontSize: 12, margin: 0 }}>compra total</label>
                                                    <span className="text-primary" style={{ fontSize: 15, margin: 0 }}>R$ {' ' + order.totalItems.toFixed(2)}</span>
                                                </span>
                                                <span style={{ display: 'flex', flexDirection: 'column', padding: 5, borderRadius: 6, backgroundColor: 'rgb(234 234 234)' }}>
                                                    <label style={{ fontSize: 12, margin: 0 }}>desconto</label>
                                                    <span className="text-primary" style={{ fontSize: 15, margin: 0 }}>R$ {' ' + order.discount.toFixed(2)}</span>
                                                </span>
                                            </span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 2 }}>
                                            <h4 className="mb-0">Total</h4>
                                            <h2 className="m-0 text-primary">R$ {' ' + (order.totalAmount + order.deliveryRate).toFixed(2)}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : 'pedido não encontrado'}
                </div>
            </div>
        </>
    )
}
export default Order;