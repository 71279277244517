import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getProfileInfos, editProfileInfos, createImg , createAddress} from '../../services/UserService';
import bgimage from '../../images/no-img-avatar.png';
import { Modal } from 'react-bootstrap';

const CadastroForm = () => {
    const [file, setFile] = useState(null);
    const [base64Image, setBase64Image] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [personData, setPersonData] = useState({
        "birthDate": " ",
        "name": " ",
        "email": "    ",
        "phone": " ",
        "taxId": " ",
        "type": 0,
        "addresses": [],
        "images": []
    });

    const [addressData, setAddressData] = useState({
        Street: "",
        Number: "",
        Neighborhood: "",
        City: "",
        State: "",
        ZipCode: "",
        Country: "",
        Latitude: 0,
        Longitude: 0
    });

    const [accountData, setAccountData] = useState({
        username: '',
        password: '',
        confirmPassword: ''
    });

    const [toggle, setToggle] = useState(false);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(true)
    const [modalNewAddress, setModalNewAddress] = useState(false)
    const [modalMSG, setModalMSG] = useState(false)

    const handlePersonChange = (e) => {
        const { name, value } = e.target;
        setPersonData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleAccountChange = (e) => {
        const { name, value } = e.target;
        setAccountData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setAddressData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const combinedData = {
            ...personData,
            address: addressData,
        };
        console.log("Payload Enviado:", combinedData);
    };

    const fileHandler = (e) => {
        const file = e.target.files[0];
        if (file && file.size < 1048576) { // Verifica se o arquivo tem menos de 1MB
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result); // Armazena a imagem em base64
            };
            reader.readAsDataURL(file)
        } else {
            setErrorMessage("A imagem deve ter menos de 1MB.");
            setFile(null);
            setBase64Image(null);
        }
    };

    const RemoveImage = () => {
        setFile(null);
        setBase64Image(null);
    };

    const createNewAddress = async (Street, Number, Neighborhood, City, State, ZipCode) => {
        try {
            const fetchProfile = async () => {
                try {
                    const response = await createAddress(Street, Number, Neighborhood, City, State, ZipCode);
                    if (response.status === 200) {
                        setToggle(false)
                        setModalMSG(true)
                        setMessage('endereço cadastrado com sucesso!')
                    } else {
                        console.error('Erro ao criar endereço:', response.statusText);
                    }
                } catch (error) {
                    console.error('Erro ao criar endereço:', error);
                }
            };
            fetchProfile();
        } catch (error) {
            console.error('Erro ao obter o valor de "p" da URL:', error);
        }
    };

    const handleAddressSubmit = (newAddress) => {
        createNewAddress(newAddress.Street, newAddress.Number, newAddress.Neighborhood, newAddress.City, newAddress.State, newAddress.ZipCode)
    };

    const checkProfile = async () => {
        try {
            const fetchProfile = async () => {
                try {
                    const response = await getProfileInfos();
                    if (response.status === 200) {
                        setPersonData(response.data.result)
                        setLoading(false)
                    } else {
                        console.error('Erro ao checar profile:', response.statusText);
                    }
                } catch (error) {
                    console.error('Erro ao checar profile:', error);
                }
            };
            fetchProfile();
        } catch (error) {
            console.error('Erro ao obter o valor de "p" da URL:', error);
        }
    };

    const insertImg = async () => {
        try {
            const fetchImg = async () => {
                const treatedBase64Image = base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
                try {
                    const response = await createImg(personData.id, file.name, 0, true, treatedBase64Image);
                    if (response.status === 200) {
                        setToggle(false)
                        setModalMSG(true)
                        setMessage('nova imagem inserida com sucesso!')
                    } else {
                        console.error('Erro ao checar profile:', response.statusText);
                    }
                } catch (error) {
                    console.error('Erro ao checar profile:', error);
                }
            };
            fetchImg();
        } catch (error) {
            console.error('Erro ao obter o valor de "p" da URL:', error);
        }
    };

    const updateProfile = async () => {
        try {
            const fetchEditProfile = async () => {
                try {
                    const response = await editProfileInfos(personData);
                    if (response.status === 200) {
                        setToggle(false)
                        setModalMSG(true)
                        setMessage('seus dados foram atualizados com sucesso!')
                    } else {
                        console.error('Erro ao checar profile:', response.statusText);
                    }
                } catch (error) {
                    console.error('Erro ao checar profile:', error);
                }
            };
            fetchEditProfile();
        } catch (error) {
            console.error('Erro ao obter o valor de "p" da URL:', error);
        }
    };

    function formatDate(isoString) {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleCancelNewAddress = ( ) => {
        setModalNewAddress(false)
    }

    useEffect(() => {
        if (!toggle) {
            checkProfile()
            setToggle(true)
        }
    }, [toggle])

    // useEffect(() => {
    //     console.log('---> personData <---')
    //     console.log(personData)
    //     console.log('---> loading <---')
    //     console.log(loading)
    // }, [personData, loading])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-12'>
                        <br />
                        <h3><i className="bi bi-card-image"></i> Imagem de exibição</h3>
                        <hr />
                    </div>
                    <div className='col-12'>

                        <div className="avatar-upload d-flex align-items-center justify-content-center">
                            <div className="change position-relative d-flex">
                                <div className="avatar-preview" style={{ maxWidth: 120 }}>
                                    {
                                        ! loading ? (
                                            personData && personData.images && personData.images.length > 0 ? (
                                                <img
                                                    id="saveImageFile"
                                                    src={file ? URL.createObjectURL(file) : personData && personData.images.length > 0 ? 'https://core.pskeromais.com.br/' + personData.images[personData.images.length - 1].url : ''}
                                                    alt={file ? file.name : "Imagem Padrão"}
                                                    style={{ maxWidth: 120 }}
                                                />
                                            ) : (
                                                <img
                                                    id="saveImageFile"
                                                    src={file ? URL.createObjectURL(file) : bgimage}
                                                    alt={file ? file.name : "Imagem Padrão"}
                                                    style={{ maxWidth: 120 }}
                                                />
                                            )
                                        ) : null
                                    }

                                </div>
                                <div className="change-btn d-flex align-items-center flex-wrap">
                                    <input
                                        type='file'
                                        onChange={fileHandler}
                                        className="form-control"
                                        id="imageUpload"
                                        accept=".png, .jpg, .jpeg"
                                    />
                                    <div className="change-btn d-flex flex-column flex-wrap gap-4"> 
                                        <label htmlFor="imageUpload" className="dlab-upload">{personData && personData.images && personData.images.length > 0 ? ("Escolher Outro Arquivo"):"Escolher Arquivo"}</label>
                                        <Link to={"#"} className="btn remove-img ms-2 bg-light" onClick={RemoveImage}>Remover</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br /><br />
                        <div className="change-btn d-flex align-items-center align-content-center bg-light p-3 justify-content-center flex-wrap">
                            <button to={"#"} className="btn btn-primary ms-2" onClick={() => insertImg()}>Enviar Imagem</button>
                        </div>

                    </div>
                    <div className='col-12'>
                        <br />
                        <h3><i className="bi bi-person-lines-fill"></i> Dados Pessoais</h3>
                        <hr />
                    </div>
                    <div className='col-md-6'>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Nome</label>
                            <input
                                type="text"
                                name="name"
                                value={personData ? personData.name : ''}
                                onChange={handlePersonChange}
                                className="form-control"
                                id="name"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="birthDate" className="form-label">Data de Nascimento</label>
                            <input
                                type="date"
                                name="birthDate"
                                value={personData ? formatDate(personData.birthDate) : ''}
                                onChange={handlePersonChange}
                                className="form-control"
                                id="birthDate"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={personData ? personData.email : ''}
                                onChange={handlePersonChange}
                                className="form-control"
                                id="email"
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="mb-3">
                            <label htmlFor="cpf" className="form-label">CPF</label>
                            <input
                                type="text"
                                name="cpf"
                                value={personData ? personData.taxId : ''}
                                onChange={handlePersonChange}
                                className="form-control"
                                id="cpf"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phone" className="form-label">Telefone</label>
                            <input
                                type="text"
                                name="phone"
                                value={personData ? personData.phone : ''}
                                onChange={handlePersonChange}
                                className="form-control"
                                id="phone"
                            />
                        </div>
                    </div>
                    <div className="change-btn d-flex align-items-center align-content-center bg-light p-3 justify-content-center flex-wrap">
                        <button type="submit" className="btn btn-primary" style={{ maxWidth: 270, width: '100%', margin: '15 auto' }}
                            onClick={() => updateProfile()}>Atualizar dados</button>
                    </div>
                    <div className='col-12'>
                        <br />
                        <h3><i className="bi bi-person-lines-fill"></i> Endereços </h3>
                        <hr />
                    </div>
                    <div className='col-12'>
                        
                        {
                            ! loading ? (
                                personData.addresses.length > 0 ? (
                                    <ul className="list-group d-flex gap-3">
                                    {
                                        personData.addresses.map((a , b) => (
                                            <li className="list-group-item shadow" key={'address-' + b}>
                                                <h4>
                                                <i className="fa-solid fa-location-dot text-primary mr-2"></i>
                                                <b>{
                                                    ' ' +
                                                    a.street +
                                                    ' ( ' +
                                                    a.zipCode +
                                                    ' ) ' +
                                                    ', Nº ' +
                                                    '' +
                                                    ' , ' +
                                                    a.city +
                                                    ' - ' +
                                                    a.state
                                                }</b></h4>
                                            </li>
                                        ))
                                    }
                                    </ul>
                                ) : (
                                    <span className='d-flex items-align-center justify-content-center'>
                                        <h3 className='text-light'>nenhum endereço cadastrado</h3>
                                    </span>
                                )
                            ) : null
                        }
                        <br /><br />
                        <div className="change-btn d-flex align-items-center align-content-center bg-light p-3 justify-content-center flex-wrap">
                            <button type="submit" className="btn btn-primary border-primary" style={{ maxWidth: 270, width: '100%', margin: '15 auto' }} onClick={() => setModalNewAddress(true)} >Cadastrar novo endereços</button>
                        </div>
                    </div>
                </div>
            </form>
            <Modal show={modalNewAddress} onHide={setModalNewAddress} centered>
                <button onClick={() => setModalNewAddress(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
                    <h4 style={{ borderBottom: '1px solid #f1f1f1', paddingBottom: 7 }}><i className="fa-solid fa-location-dot"></i> Cadastrar Novo Endereço </h4>
                    <br />
                    <NewAddressForm onSubmit={handleAddressSubmit} onCancel={() => handleCancelNewAddress()} />
                </div>
            </Modal>
            <Modal show={modalMSG} onHide={setModalMSG} centered>
                <button onClick={() => setModalMSG(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
                    <h2 style={{ paddingBottom: 3 }}><i class="bi bi-exclamation-triangle"></i></h2>
                    <h4 style={{ paddingBottom: 7 }}>{ message }</h4>
                </div>
            </Modal>
        </>
    );
};

export default CadastroForm;

const NewAddressForm = ({ onSubmit, onCancel }) => {
    const [formData, setFormData] = useState({
        Street: '',
        Number: '',
        Neighborhood: '',
        City: '',
        State: '',
        ZipCode: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', gap: 5 }} onSubmit={handleSubmit}>
            <div>
                <label>Rua:</label>
                <input
                    type="text"
                    name="Street"
                    value={formData.Street}
                    onChange={handleChange}
                    className="form-control"
                />
            </div>
            <div>
                <label>Número:</label>
                <input
                    type="text"
                    name="Number"
                    value={formData.Number}
                    onChange={handleChange}
                    className="form-control"
                />
            </div>
            <div>
                <label>Bairro:</label>
                <input
                    type="text"
                    name="Neighborhood"
                    value={formData.Neighborhood}
                    onChange={handleChange}
                    className="form-control"
                />
            </div>
            <div>
                <label>Cidade:</label>
                <input
                    type="text"
                    name="City"
                    value={formData.City}
                    onChange={handleChange}
                    className="form-control"
                />
            </div>
            <div>
                <label>Estado:</label>
                <input
                    type="text"
                    name="State"
                    value={formData.State}
                    onChange={handleChange}
                    className="form-control"
                />
            </div>
            <div>
                <label>CEP:</label>
                <input
                    type="text"
                    name="ZipCode"
                    value={formData.ZipCode}
                    onChange={handleChange}
                    className="form-control"
                />
            </div>
            <br />
            <div style={{ display: 'flex', flexDirection: 'row', gap: 3, marginTop: 20 }}>
                <button className='btn btn-primary' type="submit">Salvar Novo Endereço</button>
                <button className='btn btn-light' type="button" onClick={() => onCancel()}>Cancelar</button>
            </div>
        </form>
    );
};