import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import bgimage from '../../images/no-img-avatar.png';

const Addresses = () => {
    const [file, setFile] = useState(null);
    const [base64Image, setBase64Image] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [personData, setPersonData] = useState({
        name: "",
        email: "",
        cpf: "",
        phone: "",
        // Outros campos de cadastro de pessoa, se necessário
    });

    // Estado para os dados de endereço
    const [addressData, setAddressData] = useState({
        Street: "",
        Number: "",
        Neighborhood: "",
        City: "",
        State: "",
        ZipCode: "",
        Country: "",
        Latitude: 0,
        Longitude: 0
    });

    // Função para atualizar os dados de pessoa
    const handlePersonChange = (e) => {
        const { name, value } = e.target;
        setPersonData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Função para atualizar os dados de endereço
    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setAddressData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Função para enviar o formulário
    const handleSubmit = (e) => {
        e.preventDefault();
        // Combine os dados de pessoa e endereço em um único payload
        const combinedData = {
            ...personData,
            address: addressData, // Endereço será um objeto dentro do payload
        };

        console.log("Payload Enviado:", combinedData);
        // Aqui você faria a chamada para a API
        // axios.post('/api/register', combinedData)
        //   .then(response => console.log(response))
        //   .catch(error => console.error(error));
    };

    const fileHandler = (e) => {
        const file = e.target.files[0];
        if (file && file.size < 1048576) { // Verifica se o arquivo tem menos de 1MB
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result); // Armazena a imagem em base64
            };
            reader.readAsDataURL(file)
        } else {
            setErrorMessage("A imagem deve ter menos de 1MB.");
            setFile(null);
            setBase64Image(null);
        }
    };

    const RemoveImage = () => {
        setFile(null);
        setBase64Image(null);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-12'>
                    <h3>Cadastro de Endereço</h3>
                </div>
                <div className='col-md-6'>
                    <div className="mb-3">
                        <label htmlFor="Street" className="form-label">Rua</label>
                        <input
                            type="text"
                            name="Street"
                            value={addressData.Street}
                            onChange={handleAddressChange}
                            className="form-control"
                            id="Street"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="Number" className="form-label">Número</label>
                        <input
                            type="text"
                            name="Number"
                            value={addressData.Number}
                            onChange={handleAddressChange}
                            className="form-control"
                            id="Number"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="Neighborhood" className="form-label">Bairro</label>
                        <input
                            type="text"
                            name="Neighborhood"
                            value={addressData.Neighborhood}
                            onChange={handleAddressChange}
                            className="form-control"
                            id="Neighborhood"
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="mb-3">
                        <label htmlFor="City" className="form-label">Cidade</label>
                        <input
                            type="text"
                            name="City"
                            value={addressData.City}
                            onChange={handleAddressChange}
                            className="form-control"
                            id="City"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="State" className="form-label">Estado</label>
                        <input
                            type="text"
                            name="State"
                            value={addressData.State}
                            onChange={handleAddressChange}
                            className="form-control"
                            id="State"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="ZipCode" className="form-label">CEP</label>
                        <input
                            type="text"
                            name="ZipCode"
                            value={addressData.ZipCode}
                            onChange={handleAddressChange}
                            className="form-control"
                            id="ZipCode"
                        />
                    </div>

                    {/* <div className="mb-3">
                        <label htmlFor="Country" className="form-label">País</label>
                        <input
                            type="text"
                            name="Country"
                            value={addressData.Country}
                            onChange={handleAddressChange}
                            className="form-control"
                            id="Country"
                        />
                    </div> */}

                    {/* Latitude e Longitude (opcional ou desativado) */}
                    {/* <div className="mb-3">
        <label htmlFor="Latitude" className="form-label">Latitude</label>
        <input
          type="number"
          name="Latitude"
          value={addressData.Latitude}
          onChange={handleAddressChange}
          className="form-control"
          id="Latitude"
          disabled
        />
      </div>

      <div className="mb-3">
        <label htmlFor="Longitude" className="form-label">Longitude</label>
        <input
          type="number"
          name="Longitude"
          value={addressData.Longitude}
          onChange={handleAddressChange}
          className="form-control"
          id="Longitude"
          disabled
        />
      </div> */}
                </div>
                <button type="submit" className="btn btn-primary" style={{maxWidth: 270 , margin: '15 auto'}}>Enviar</button>
            </div>
        </form>
    );
};

export default Addresses;