import React, { useEffect, useState } from 'react';
import { Modal, Nav, Tab } from 'react-bootstrap';
import BannerSlider from '../pages/Dashboard/Dashboard/BannerSlider'
import { CartAdd, CartRmv } from "../../store/actions/CartActions"
import { ShopperRequest, productsShop, getProduct } from '../../services/ProductService';
import { calculateDiscountPercentage } from '../helpers/helpers'
import tabData from './subtabs/tabDataMain.json'

const Menu = (props) => {
    let dummy = JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : {
        cart: {
            loja: "",
            status: "aguardando",
            itens: []
        }
    }
    let parameters = new URLSearchParams(window.location.search);
    let pUrlValue = parameters.get('p');
    let cartToken = JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : { cart: {} }
    // const dummyShoppers = ShoppersComponentMockUp();
    // const dummyProducts = SnackArrayComponentMockUp();
    const [shopper, setShopper] = useState({});
    const [isBuying, setIsBuying] = useState(cartToken.cart.loja ? cartToken.cart.loja.id === pUrlValue ? true : false : false);
    const [products, setProducts] = useState([])
    const [product, setProduct] = useState({})
    const [productId, setProductId] = useState(null)
    const [shopperId, setShopperId] = useState('-')
    const [qtd, setQtd] = useState(0);
    const [lojaSelecionada, setLojaSelecionada] = useState(cartToken.cart.loja);
    const [modalShow, setModalShow] = useState(false);
    const [modalProduct, setModalProduct] = useState(false);
    const [cart, setCart] = useState(dummy);

    const [productNameSearch, setProductNameSearch] = useState('');
    const [onSearch, setOsSearch] = useState(false);
    const [lastSearch, setLastSearch] = useState('');
    const [searchSteps, setSearchSteps] = useState('name');
    const [modalSearch, setModalSearch] = useState(false);
    const [searchValueType, setValueType] = useState('name');

    const getURLVariable = (variable) => {
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        console.error('Variável da URL não encontrada: ' + variable);
        return null;
    };

    const qtfcr = (ope, data) => {
        let cartDummy = JSON.parse(localStorage.getItem('cart'))
        if (!cartDummy || cartDummy.cart.loja === "") {
            setModalShow(true);
        } else {
            if (ope === 'plus') {
                let dummyValue = CartAdd(data)
                setCart(dummyValue)
            } else if (ope === 'minus') {
                let dummyValue = CartRmv(data)
                setCart(dummyValue)
            }
        }
    };

    useEffect(() => {
        if (shopperId !== '-') {
            const fetchProducts = async () => {
                try {
                    const response = await getProduct(shopperId);
                    if (response.status === 200) {
                        setProduct(response.data.result);
                        setModalProduct(true)
                    } else {

                    }
                } catch (error) {

                }
            };
            fetchProducts();
        }
    }, [shopperId]);

    useEffect(() => {
        let dummy = JSON.stringify(cart)
        localStorage.setItem('cart', dummy)
    }, [cart]);

    const shopConfirm = () => {
        const parametro = getURLVariable('p');
        if (parametro) {
            const cartDummy = JSON.parse(localStorage.getItem('cart')) || {};
            cartDummy.cart = cartDummy.cart || {};
            cartDummy.cart.loja = shopper;
            cartDummy.cart.status = 'em uso';
            cartDummy.cart.itens = [];
            localStorage.setItem('cart', JSON.stringify(cartDummy));
            setIsBuying(true)
        }
    };

    useEffect(() => {
        if (productId === null) {
            const checkProduct = async () => {
                try {
                    const params = new URLSearchParams(window.location.search);
                    const pValue = params.get('p');
                    if (pValue) {
                        setProductId(pValue)
                        const fetchProducts = async () => {
                            try {
                                const response = await ShopperRequest(pValue);
                                if (response.status === 200) {
                                    setShopper(response.data.result)
                                } else {
                                    console.error('Erro ao obter os produtos:', response.statusText);
                                }
                            } catch (error) {
                                console.error('Erro ao obter os produtos:', error);
                            }
                        };
                        fetchProducts();
                    } else {
                        console.log('O parâmetro "p" não está presente na URL ou seu valor é vazio.');
                    }
                } catch (error) {
                    console.error('Erro ao obter o valor de "p" da URL:', error);
                }
            };
            checkProduct();
        }
    }, [shopper]);

    useEffect(() => {
        if (products.length === 0) {
            const checkProduct = async () => {
                try {
                    const params = new URLSearchParams(window.location.search);
                    const pValue = params.get('p');
                    if (pValue) {
                        setProductId(pValue)
                        const fetchProducts = async () => {
                            try {
                                const response = await productsShop(pValue, 1, 20);
                                if (response.status === 200) {
                                    setProducts(response.data.result)
                                } else {
                                    console.error('Erro ao obter os produtos:', response.statusText);
                                }
                            } catch (error) {
                                console.error('Erro ao obter os produtos:', error);
                            }
                        };
                        fetchProducts();
                    } else {
                        console.log('O parâmetro "p" não está presente na URL ou seu valor é vazio.');
                    }
                } catch (error) {
                    console.error('Erro ao obter o valor de "p" da URL:', error);
                }
            };
            checkProduct();
        }
    }, [products]);

    useEffect(() => {
        if (localStorage.cart) {
            const cartDummy = JSON.parse(localStorage.cart);
            let somaQuantidades = 0;
            if (cartDummy.cart.itens && cartDummy.cart.itens.length > 0) {
                cartDummy.cart.itens.forEach(item => {
                    if (item.hasOwnProperty('quantidade')) {
                        somaQuantidades += item.quantidade;
                    }
                });
            }
            if (isBuying) {
                console.log('cartDummy.cart.itens.status === em uso && cartDummy.cart.loja')
            }
            setQtd(somaQuantidades);
        } else {
            setQtd(0);
        }
    }, [cart]);

    const handleStoreFilter = ( value, Page, PerPage ) => {
        const storeFilter = async () => {
           try {
              const response = await productsShop(value, Page, PerPage);
              if (response.status === 200) {
                 setProducts(response.data.result);
                 setOsSearch(true);
                 setLastSearch(value);
                 setModalSearch(false);
              }
           } catch (error) {
              console.error("Erro ao filtrar lojas:", error);
           }
        };
        storeFilter();
    }; 

    useEffect(( ) => {
        console.log('productNameSearch')
        console.log(productNameSearch)
    },[productNameSearch])

    const lojaId = localStorage.cart ? JSON.parse(localStorage.cart) : { cart: {} }

    return (
        <>
            {
                qtd !== 0 ?
                    <span style={{ display: 'flex', justifyContent: 'center', position: 'absolute', left: 'Calc(50% + 65px)', top: '97px', zIndex: '9999', width: 20, height: 20, position: 'fixed', backgroundColor: '#f54749', marginRight: -150, borderRadius: '100%', fontSize: 12, paddingTop: 2, color: 'white', }}>
                        {qtd}
                    </span>
                    : qtd === 0 ?
                        null
                        : null
            }
            <div className="custom-tab-1 pb-3">
                <Tab.Container>
                    <Nav as="ul" className="navigatore">
                        {tabData.map((data, i) => (
                            <Nav.Item as="li" key={i}>
                                <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                                    <i className={`la la-${data.icon} me-2`} />
                                    {data.name}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                        {lojaId.cart.loja && lojaId.cart.loja.id? (
                            lojaId.cart.loja.id !== lojaSelecionada.id ?
                                <Nav.Item as="li">
                                    <Nav.Link style={{ marginLeft: 6, backgroundColor: '#f54749', color: 'white', borderRadius: 4 }} eventKey={"Loja Ativa".toLowerCase()} href={`/loja?p=${lojaId.cart.loja.id}`}>
                                        <i className={`bi bi-shop me-2`} style={{ color: 'white' }} />
                                        Loja Ativa
                                    </Nav.Link>
                                </Nav.Item>
                                :
                                <span style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap', aligniItems: 'center', marginLeft: 6, border: '1px #f54749 solid', color: '#f54749', borderRadius: 4, padding: '0 7px' }}>
                                    <i className={`bi bi-shop me-2`} style={{ color: '#f54749' }} />
                                    Loja Ativa Atual
                                </span>
                            ): null}
                    </Nav>
                </Tab.Container>
            </div>
            
            <div className="row">
                {
                    lojaSelecionada !== null ?
                        <div className="col-xl-12" style={{ maxWidth: '400px', margin: '0 auto' }}>
                            <br />
                            <span className='d-flex flex-wrap justify-content-center gap-3'>
                                <span>
                                    {
                                        shopper.images && shopper.images.length > 0 ? (
                                            <span style={{ display: 'flex', margin: '0 auto', width: 80, height: 80, borderRadius: '100%', backgroundColor: '#dedede', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
                                                <img className="" style={{ width: '100%' }} src={'https://core.pskeromais.com.br/' + shopper.images[shopper.images.length - 1].url} />
                                            </span>
                                        ):
                                        <span style={{ display: 'flex', margin: '0 auto', width: 50, height: 50, borderRadius: '100%', backgroundColor: '#dedede', justifyContent: 'center', alignItems: 'center' }}>
                                            <i className='bi bi-shop' style={{ fontSize: '30px' }} />
                                        </span>
                                    }
                                    <br />
                                </span>
                                <span>
                                    <h3 style={{ margin: 0 }}>{shopper.name}</h3>
                                    <p style={{ margin: 0 }}><i className="bi bi-whatsapp" style={{ marginRight: 4, color: '#F54749' }}></i>{shopper.phone}</p>
                                    <p style={{ margin: 0 }}><i className="bi bi-telephone" style={{ marginRight: 4, color: '#F54749' }}></i>{shopper.email}</p>
                                </span>
                            </span>
                            <br />
                            {shopper.deliveryTax > 0?
                                <span className='d-flex flex-wrap flex-column justify-content-center'>
                                    taxa de entrega:
                                    <h3 style={{ margin: 0 }}>{shopper.deliveryTax.toFixed(2)} RS</h3>
                                </span>
                            :<span className='d-flex flex-wrap flex-column justify-content-center text-center'>
                                <span style={{backgroundColor: '#dedede', padding: 6}}>
                                    sem taxa de entrega
                                </span>
                            </span>
                            }
                        </div>
                        : null
                }
                {/* <div className="col-xl-12 mb-4" style={{padding: 0, padding: 0, margin: '10px 0', backgroundColor: "rgb(247 247 247)"}}>
                    <span style={{maxWidth: 700 , marginTop: '25px' , display: 'block' , margin: '25px auto'}}>
                        <BannerSlider /> 
                    </span>
                </div> */}
                { !onSearch? 
                     <span style={{ marginTop: 10, padding: '5px 0 20px 0' , display: 'flex' , flexDirection: 'row' , justifyContent: 'center' ,alignItems: 'center' , alignContent: 'center', gap: 5 , width: '100%'}}>
                        {/* <button className="btn btn-primary-outline border-primary text-primary" onClick={() => setModalSearch(true)}>
                           procurar produto
                        </button> */}
                     </span>
                  : onSearch? 
                     <div  style={{ marginTop: 10 , padding: '5px 0 20px 0' , display: 'flex' , flexDirection: 'row' , gap: 10 , justifyContent: 'center'}}>
                        <div style={{ display: 'flex', flexDirection: 'column' , justifyContent: 'center' , alignItems: 'center'}}> 
                           <label><i className="fa-solid fa-magnifying-glass"></i> buscando por:</label> 
                           <h4 style={{backgroundColor: 'rgb(241, 241, 241)' , padding: "6px 17px"}}><b className="text-primary">{lastSearch}</b></h4>
                        </div>
                        <span style={{ display: 'flex' , flexDirection: 'row' , justifyContent: 'center' ,alignItems: 'center' , alignContent: 'center', gap: 5 }}>
                           <button className="btn btn-primary-outline border-primary text-primary" onClick={() => setModalSearch(true)}>
                              procurar nova loja
                           </button>
                        </span>
                     </div>   
                  :  null
                }
                <div className="col-xl-12" style={{ padding: 0 }}>
                    <ul className="btn-reveal-trigger row" style={{ padding: 0 }}>
                        {
                            products.length !== 0 ?
                                products.map((Data, Index) => (
                                    <li className="col-sm-6 col-md-4"
                                        style={{
                                            marginBottom: 6,
                                            borderRadius: 6,
                                        }} key={Index}>
                                        <div
                                            className="p-3"
                                            style={{
                                                boxShadow: '2px 2px 2px #f1f1f1',
                                            }}>
                                            <span style={{ display: 'flex', flexDirection: 'column', gap: 3, position: 'relative' }}>
                                                {
                                                    Data.images.length > 0 ?
                                                        <img className="" style={{ width: '100%' }} src={'https://core.pskeromais.com.br/' + Data.images[0].url} />
                                                        :
                                                        <span style={{
                                                            width: '100%',
                                                            height: 200,
                                                            backgroundImage: "gray",
                                                            margin: '0 auto',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
                                                        }}>
                                                            <i className='bi bi-gift' style={{ fontSize: 45, color: 'white' }} />
                                                        </span>
                                                }
                                                <div style={{ width: '100%' }}>                                               <br />
                                                    <h3 className="mb-0 fs--1" style={{ width: '100%', textAlign: 'center' }}>
                                                        {Data.name}
                                                    </h3>
                                                </div>
                                                <span style={{ maxWidth: '100px', width: '100%', margin: '6px auto', backgroundColor: 'rgb(241, 241, 241)', padding: 6, borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", position: 'relative' }}>
                                                    {Data.salePrice > Data.onSalePrice ?
                                                        <i style={{ margin: '0 4px', textDecoration: 'line-through', color: '#a0a0a0' }}>{Data.salePrice.toFixed(2) + ' R$'}</i>
                                                        : null}
                                                    <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{Data.onSalePrice.toFixed(2) + ' R$'}</span>
                                                    {Data.salePrice > Data.onSalePrice ?
                                                        <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', alignContent: 'center', fontWeight: 'bold', backgroundColor: '#F54749', color: 'white', width: 60, height: 60, padding: 10, borderRadius: '100%', fontSize: 18, position: 'absolute', top: '-15px', right: '-40px' }}>
                                                            <span style={{ height: '18px' }} >{calculateDiscountPercentage(Data.salePrice, Data.onSalePrice) + '%'}</span>
                                                            <span style={{ height: '18px', fontWeight: 'normal' }} >OFF</span>
                                                        </span>
                                                        : null}
                                                </span>
                                            </span>
                                            {isBuying ?
                                                <div style={{ textAlign: 'center', width: "100%", display: 'flex', flexDirection: 'row', gap: 3, justifyContent: 'center', alignItems: 'center' }}>
                                                    <button onClick={() => qtfcr('minus', Data)} className="btn btn-primary d-flex justify-content-center align-items-center white d-flex p-1 px-2 mt-2 mr-auto ml-auto" style={{ fontSize: 11, width: 30, height: 30 }}>
                                                        <i className="bi bi-dash" style={{ fontSize: 20 }}></i>
                                                    </button>
                                                    <button onClick={() => qtfcr('plus', Data)} className="btn btn-secondary d-flex justify-content-center align-items-center white d-flex p-1 px-2 mt-2 mr-auto ml-auto" style={{ fontSize: 11, width: 30, height: 30 }}>
                                                        <i className="bi bi-plus" style={{ fontSize: 20 }}></i>
                                                    </button>
                                                </div>
                                                : <div style={{ textAlign: 'center', width: "100%", display: 'flex', flexDirection: 'row', gap: 3, justifyContent: 'center', alignItems: 'center' }}>
                                                    <button onClick={() => setModalShow(!modalShow)} className="btn btn-secondary d-flex justify-content-center align-items-center white d-flex p-1 px-2 mt-2 mr-auto ml-auto">
                                                        fazer pedido
                                                    </button>
                                                </div>}
                                            <div style={{ textAlign: 'center', width: "100%", display: 'flex', flexDirection: 'row', gap: 3, justifyContent: 'center', alignItems: 'center' }}>
                                                <button onClick={() => setShopperId(Data.id)} className="btn btn-primary-outline border border-primary text-primary py-1 px-3 mt-2 mr-auto ml-auto" style={{ maxWidth: 100 }}>
                                                    produto
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                )) : null
                        }
                    </ul>
                </div>
            </div>
            <Modal className="custom-modal fade d-flex align-items-center justify-content-center " show={modalShow}>
                <button onClick={() => setModalShow(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
                    <i className="bi bi-basket" style={{ fontSize: 30 }}></i>
                    <h4 style={{ borderBottom: '1px solid #f1f1f1', paddingBottom: 7 }}>inciar compras em:</h4>
                    <h2>{shopper ? shopper.name : null}</h2>
                    <br />
                    <div onClick={() => setModalShow(false)} className='d-flex align-items-center justify-content-center flex-row' style={{ gap: 4 }}>
                        <button onClick={() => shopConfirm()} className='btn btn-primary'>
                            começar compras nessa loja
                        </button>
                        <button className='btn btn-light'>
                            cancelar
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal className="custom-modal fade d-flex align-items-center justify-content-center " show={modalProduct}>
                <button onClick={() => { setModalProduct(false); setProductId('-') }} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
                    {product.name ?
                        <>
                            <div className="p-3">
                                <span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                    {
                                        product.images.length > 0 ?
                                            <img className="" style={{ width: 130 }} src={'https://core.pskeromais.com.br/' + product.images[0].url} />
                                            : <span style={{
                                                width: 130,
                                                height: 80,
                                                backgroundImage: "gray",
                                                margin: '0 auto',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
                                            }}>
                                                <i className='bi bi-gift' style={{ fontSize: 45, color: 'white' }} />
                                            </span>
                                    }
                                </span>
                            </div>
                            <span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                <div className="media d-flex justify-content-between">
                                    <div>
                                        <br />
                                        <h3 className="mb-0 fs--1">
                                            {product.name}
                                        </h3>
                                    </div>
                                </div>
                                <hr />
                                <p style={{ textIndent: '24px', textAlign: 'justify' }}>
                                    {product.description}
                                </p>
                                <span style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                                    <span>
                                        <span style={{ color: 'rgb(245, 71, 73)' }}>
                                            <i className="bi bi-arrows-fullscreen"></i> tamanho
                                        </span>
                                        <br />
                                        <span style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                            {product.height + ' x ' + product.width + ' x ' + product.length}
                                        </span>
                                    </span>
                                    <span>
                                        <span style={{ color: 'rgb(245, 71, 73)' }}>
                                            <i className="bi bi-stack" ></i> peso
                                        </span>
                                        <br />
                                        <span style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                            {product.weight + ' g'}
                                        </span>
                                    </span>
                                    <span>
                                        <span style={{ color: 'rgb(245, 71, 73)' }}>
                                            <i className="bi bi-wallet2"></i> preço:
                                        </span>
                                        <span style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                                            <span style={{ width: '50%' }}>
                                                <label style={{ margin: '0' }}>custo</label>
                                                <hr style={{ margin: '2px 0' }} />
                                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{product.costPrice + ' R$'}</span>
                                            </span>
                                            <span style={{ width: '50%' }}>
                                                <label style={{ margin: '0' }}>venda</label>
                                                <hr style={{ margin: '2px 0' }} />
                                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{product.salePrice + ' R$'}</span>
                                            </span>
                                        </span>
                                    </span>
                                    {/* <span style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                                        <span style={{ color: 'rgb(245, 71, 73)' }}>
                                            <i className="bi bi-archive"></i> estoque:
                                        </span>
                                        {Data.Store ? Data.Store : <span style={{ color: 'red' }}>- sem estoque -</span>}
                                    </span> */}
                                </span>
                            </span>
                        </>
                        : null}
                    <br />
                    <div onClick={() => { setModalProduct(false); setProductId('-') }} className='d-flex align-items-center justify-content-center flex-row' style={{ gap: 4 }}>
                        <button className='btn btn-light'>
                            fechar
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal show={modalSearch}>
                <button onClick={() => setModalSearch(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <span
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                        justifyContent: 'spaceBetween',
                        width: '100%',
                        margin: '10px 0',
                        justifyContent: 'space-around',
                        justifyContent: 'center',
                        alignContent: 'center'
                    }}
                >
                    <span
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'column',
                            justifyContent: 'spaceBetween',
                            justifyContent: 'space-around',
                            padding: '14px',
                            justifyContent: 'center',
                            gap: '10px',
                            border: '1px #f6f6f6 solid'
                        }}
                    >
                        <span style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            <h5 className="" style={{ margin: 0 }}><i className="fa-solid fa-magnifying-glass"></i></h5>
                            <h5 className="text-primary" style={{ margin: 0 }}>Pesquisar Loja</h5>
                        </span>
                        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                            {searchSteps === 'name' ?
                                <span style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 5 }}>
                                    <input onChange={(e) => setProductNameSearch(e.target.value)} value={productNameSearch.length > 0?productNameSearch.length:''} placeholder="pesquisar por nome" style={{ width: '100%', padding: '2px 5px', textAlign: 'center', height: 40 }} />
                                    <button className={`btn btn-light`} style={{ fontSize: 12 }} onClick={() => handleStoreFilter(searchValueType, productNameSearch)}>buscar</button>
                                </span>
                                : searchSteps === 'address' ?
                                    <span style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 5 }}>
                                        <span style={{ display: 'flex', flexDirection: 'row' }}>
                                            <input placeholder="pesquisar por endereço" style={{ width: '100%', padding: '2px 5px', textAlign: 'center', height: 40 }} />
                                            <select style={{ width: '120px', textAlign: 'center', height: 40, color: 'gray' }}>
                                                <option onClick={(e) => { setValueType('street') }} >rua</option>
                                                {/* <option>número</option> */}
                                                <option onClick={(e) => setValueType('neighborhood')}>bairro</option>
                                                <option onClick={(e) => setValueType('city')}>cidade</option>
                                            </select>
                                        </span>
                                        <button
                                            className={`btn btn-light`}
                                            style={{ fontSize: 12 }}>
                                            buscar
                                        </button>
                                    </span>
                                    : null}
                        </span>
                    </span>
                </span>
            </Modal>
        </>
    )
}
export default Menu; 