import axios from 'axios';


export function productsList(id, page, PerPage) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "StoreId": id,
        "Page": page,
        "PerPage": PerPage
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetProductsRequest`,
        postData, config,
    );
}

export function getProduct(id) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "ProductId": id
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetProductRequest`,
        postData, config,
    );
}

export function shoppersList(page, PerPage) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "Page": page,
        "PageSize": PerPage,
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetStoreByFiltersCommand`,
        postData, config,
    );
}

export function ordersList(page, PerPage) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "Page": page,
        "PerPage": PerPage
      }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetOrdersByFilterRequest`,
        postData, config,
    );
}

export function ShopperRequest(id) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "StoreId": id
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetStoreRequest`,
        postData, config,
    );
}

export function productsShop( id , page, perPage) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "StoreId": id,
        "Page": page,
        "PerPage": perPage
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetProductsRequest`,
        postData, config,
    );
}

export function filterShops(Value , Type , Page, PageSize) {
    console.log('chega aqui?')
    console.log(Type)
    console.log(Value)
    console.log(Page)
    console.log(PageSize)
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));

    const token = 'Bearer ' + dumbToken.data;
    console.log(dumbToken)
    const config = {
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    };

    console.log(config)

    const postData = {};
    
    if(Type === 'name') {
        postData.Name = Value
    } else if (Type === 'street') {
        postData.Street = Value
    } else if (Type === 'neighborhood') {
        postData.Neighborhood = Value
    } else if (Type === 'city') {
        postData.City = Value
    }

    postData.Page = Page; 
    postData.PageSize = PageSize; 

    console.log('postData AXIOS')
    console.log(postData)

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetStoreByFiltersCommand`,
        postData, config
    );
}