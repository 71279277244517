const getURLVariable = (variable) => {
   const query = window.location.search.substring(1);
   const vars = query.split('&');
   for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
         return decodeURIComponent(pair[1]);
      }
   }
   console.error('Variável da URL não encontrada: ' + variable);
   return null; // Retorna null se a variável não for encontrada
};

export { getURLVariable };

const formatDate = (isoString) => {
   const date = new Date(isoString);

   const day = String(date.getDate()).padStart(2, '0');
   const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() retorna um valor de 0 a 11
   const year = date.getFullYear();

   return `${day}-${month}-${year}`;
}

export { formatDate };

const extractDate = (isoString) => {
   const date = new Date(isoString);
   const day = String(date.getDate()).padStart(2, '0');
   const month = String(date.getMonth() + 1).padStart(2, '0');
   const year = date.getFullYear();
   return `${day}-${month}-${year}`;
}

export { extractDate };

const extractTime = (isoString) => {
   const date = new Date(isoString);
   const hours = String(date.getHours()).padStart(2, '0');
   const minutes = String(date.getMinutes()).padStart(2, '0');
   const seconds = String(date.getSeconds()).padStart(2, '0');
   return `${hours}:${minutes}:${seconds}`;
}

export { extractTime };

const formatCPF = (value) => {
   // Converte o valor para string
   const str = value.toString();
   // Verifica se o valor tem 11 dígitos
   if (str.length !== 11) {
     throw new Error("Valor inválido para CPF");
   }
   // Formata o CPF
   return str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export { formatCPF };

const formatCNPJ = (value) => {
   // Converte o valor para string
   const str = value.toString();
   // Verifica se o valor tem 14 dígitos
   if (str.length !== 14) {
     return value
   }
   // Formata o CNPJ
   return str.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export { formatCNPJ };

const formatPhoneNumber = (value) => {
   // Remove o prefixo '+'
   const str = value.toString().replace(/^\+/, '');
   // Verifica se o valor tem entre 10 e 14 dígitos
   if (str.length < 10 || str.length > 14) {
      return value
   }
   // Completa com zeros à esquerda se necessário
   const paddedStr = str.padStart(14, '0');
   // Formata o número de telefone
   return paddedStr.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, "($2) $3.$4");
}

export { formatPhoneNumber };

const calculateDiscountPercentage = (salePrice, onSalePrice) => {
   if (salePrice > 0 || onSalePrice < salePrice) {
      const discount = ((salePrice - onSalePrice) / salePrice) * 100;
      if(discount > 0) {
         return  parseFloat(discount.toFixed(2)); // Arredonda para duas casas decimais
      }
   }
}

export { calculateDiscountPercentage }