import React, { useEffect, useState } from 'react';
import { Dropdown, Modal, Nav, Tab } from 'react-bootstrap';

import tabData from "./subtabs/tabDataMain.json"
import { ordersListMain } from '../../services/OrderService'
import { extractDate, extractTime, formatPhoneNumber, formatCNPJ, formatCPF } from '../helpers/helpers';

import SimplePagination from '../components/pagination/Paginations'

const statusMap = {
    0: 'Pendente',
    1: 'Confirmado',
    2: 'Enviado',
    3: 'Entregue',
    4: 'Cancelado',
    5: 'Rejeitado'
};

const FoodOrder = () => {
    const [orders, setOrders] = useState([]);
    const [orderTaken, setOrderTaken] = useState({});
    const [toggle, setToggle] = useState(false);
    const [refuseModal, setRefuseModal] = useState(false);
    const [acceptModal, setAcceptModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(null);

    const setModal = (modal, type, content) => {
        if (type === 'accept') {
            setAcceptModal(modal)
        } else if (type === 'refuse') {
            setRefuseModal(modal)
        }
        setOrderTaken(content)
        setToggle(false)
    }

    const handlePageChange = (page) => {
        const fetchShoppers = async () => {
            try {
                const response = await ordersListMain(page, 20);
                if (response.status === 200) {
                    setOrders(response.data.result);
                    setTotalItems(response.data.totalPage)
                    setCurrentPage(page)
                } else {

                }
            } catch (error) {

            }
        };
        fetchShoppers();
    };

    useEffect(() => {
        if (!toggle) {
            const Orders = async () => {
                try {
                    const fetchProducts = async () => {
                        try {
                            const response = await ordersListMain();
                            if (response.status === 200) {
                                setOrders(response.data.result)
                                setTotalItems(response.data.totalPage)
                            } else {
                                console.error('Erro ao obter os produtos:', response.statusText);
                            }
                        } catch (error) {
                            console.error('Erro ao obter os produtos:', error);
                        }
                    };
                    fetchProducts();
                    setToggle(true)
                } catch (error) {
                    console.error('Erro ao obter o valor de "p" da URL:', error);
                }
            };
            Orders()
        }
    }, [toggle])

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        {orders
                            .sort((a, b) => b.number - a.number)
                            .map((item, index) => (
                                <div className="col-xl-4 col-sm-6 sp15 mb-4" key={'order-' + index}>
                                    <div className="h-auto b-hover">
                                        <div className="p-3" style={{ boxShadow: '2px 2px 2px #f1f1f1' }}>
                                            <h4
                                                style={{
                                                    marginBottom: 1,
                                                    backgroundColor: '#f54749',
                                                    margin: '-1rem -1rem 1rem -1rem',
                                                    padding: '1rem',
                                                    color: 'white',
                                                }}
                                            >
                                                Pedido Nº {item.number}
                                            </h4>
                                            <div className="d-flex flex-row" style={{ justifyContent: 'space-between' }}>
                                                <div className="text-left">
                                                    <p style={{ marginBottom: 1 }}>
                                                        <i className="bi bi-calendar-date text-primary" style={{ marginRight: 4 }}></i>
                                                        {extractDate(item.orderDate)} |{' '}
                                                        <i className="bi bi-clock  text-primary" style={{ marginRight: 4 }}></i>
                                                        {extractTime(item.createDate)}
                                                    </p>
                                                    <h5 style={{ marginBottom: 1 }}>{item.store.name}</h5>
                                                    <p style={{ marginBottom: 1 }}>
                                                        <i className="bi bi-person-badge text-primary" style={{ marginRight: 3 }}></i>
                                                        {item.customer.taxId} |{' '}
                                                        <i className="bi bi-telephone-fill  text-primary"></i> {item.store.phone}
                                                    </p>
                                                </div>
                                            </div>
                                            <hr />
                                            <i className="fa-solid fa-location-dot text-primary" style={{ marginRight: 7 }}></i>
                                            {item.address.street +
                                                ' ( ' +
                                                item.address.zipCode +
                                                ' ) ' +
                                                ', Nº ' +
                                                '' +
                                                ' , ' +
                                                item.address.city +
                                                ' - ' +
                                                item.address.state}
                                            <div
                                                className="d-flex flex-row align-items-left"
                                                style={{
                                                    backgroundColor: 'rgb(234, 234, 234)',
                                                    padding: '6px 10px',
                                                    borderRadius: '3px',
                                                    maxHeight: '38px',
                                                    gap: 5,
                                                    margin: '10px 0',
                                                }}
                                            >
                                                <label className="mb-0">status</label>
                                                <h4 className="mb-0 text-primary">{statusMap[item.orderStatus]}</h4>
                                            </div>
                                            <div className="order-menu">
                                                <h6>Itens</h6>
                                                {item.orderItems.map((a, b) =>
                                                    b < 2 ? (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                gap: 2,
                                                                paddingBottom: 5,
                                                                marginBottom: 5,
                                                                borderBottom: '1px #dedede solid',
                                                            }}
                                                            key={'orderItens-' + b}
                                                        >
                                                            <div style={{ display: 'flex' }}>
                                                                <img src={'https://core.pskeromais.com.br/' + a.product.images[0].url} />
                                                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                                                    <p style={{ fontSize: 13, marginBottom: 0, marginTop: 0, height: 27 }}>
                                                                        {a.product.name}
                                                                    </p>
                                                                    <b className="text-primary">{a.quantity + ' x'}</b>
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'flex-end',
                                                                    marginTop: 5,
                                                                    gap: 5,
                                                                }}
                                                            >
                                                                <span style={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <label style={{ marginRight: 5, fontSize: 9 }}>unit</label>
                                                                    <span
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            alignItems: 'flex-end',
                                                                            fontSize: 10,
                                                                            borderBottom: '1px dotted rgb(182 182 182',
                                                                            paddingBottom: 4,
                                                                        }}
                                                                    >
                                                                        {a.product.salePrice ? (
                                                                            <i
                                                                                style={{
                                                                                    margin: '0 4px',
                                                                                    textDecoration: 'line-through',
                                                                                    color: '#a0a0a0',
                                                                                }}
                                                                            >
                                                                                {a.product.salePrice.toFixed(2) + ' R$'}
                                                                            </i>
                                                                        ) : null}
                                                                        <b className="text-primary">
                                                                            {' ' + a.unitPrice.toFixed(2) + ' R$'}
                                                                        </b>
                                                                    </span>
                                                                </span>
                                                                <span>
                                                                    <label style={{ marginRight: 5, fontSize: 9 }}>total</label>
                                                                    <b className="text-primary">{' ' + a.total.toFixed(2) + ' R$'}</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                )}
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                                    <h4 className="mb-0">Total do pedido</h4>
                                                    <span style={{ display: 'flex', gap: 5, marginBottom: 7, marginTop: 4 }}>
                                                        <span
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                padding: 5,
                                                                borderRadius: 6,
                                                                backgroundColor: 'rgb(234 234 234)',
                                                            }}
                                                        >
                                                            <label style={{ fontSize: 12, margin: 0 }}>entrega</label>
                                                            <span className="text-primary" style={{ fontSize: 15, margin: 0 }}>
                                                                R$ {' ' + item.deliveryRate.toFixed(2)}
                                                            </span>
                                                        </span>
                                                        <span
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                padding: 5,
                                                                borderRadius: 6,
                                                                backgroundColor: 'rgb(234 234 234)',
                                                            }}
                                                        >
                                                            <label style={{ fontSize: 12, margin: 0 }}>compra total</label>
                                                            <span className="text-primary" style={{ fontSize: 15, margin: 0 }}>
                                                                R$ {' ' + item.totalItems.toFixed(2)}
                                                            </span>
                                                        </span>
                                                        <span
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                padding: 5,
                                                                borderRadius: 6,
                                                                backgroundColor: 'rgb(234 234 234)',
                                                            }}
                                                        >
                                                            <label style={{ fontSize: 12, margin: 0 }}>desconto</label>
                                                            <span className="text-primary" style={{ fontSize: 15, margin: 0 }}>
                                                                R$ {' ' + item.discount.toFixed(2)}
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <label style={{ fontSize: 12, margin: 0 }}>
                                                        <b>TOTAL</b>
                                                    </label>
                                                    <h2 className="m-0 text-primary">
                                                        R$ {' ' + (item.totalAmount + item.deliveryRate).toFixed(2)}
                                                    </h2>
                                                </div>
                                                <hr />
                                                <div style={{ display: 'flex', gap: 5 }}>
                                                    <a href={`/pedido?p=${item.id}`} className="btn btn-primary" style={{ padding: '3px 10px' }}>
                                                        PEDIDO
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                <div className="col-12">
                    {
                        orders.length > 0 ?
                            <SimplePagination
                                totalItems={totalItems}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                            : null
                    }
                </div>
            </div>
        </>
    )
}
export default FoodOrder;